<template>
	<div class="page">
		<div class="inf">
			<div class="inf_icon">
				<img src="../assets/img/joinus.jpeg" alt="" />
			</div>
			<div class="inf_text" v-html="inf_text"></div>
		</div>
		<el-collapse accordion v-model="activeNames">
			<el-collapse-item v-for="(item, index) in list" :key="index" :name="item.id" :title="item.title">
				<div v-html="item.text" class="detail"></div>
			</el-collapse-item>
		</el-collapse>
	</div>
</template>

<script>
export default {
	data() {
		return {
			list: [],
			activeNames: '',
			inf_text: ''
		}
	},
	mounted() {
		this.basePage()
	},
	methods: {
		basePage() {
			// 页面初始化
			switch (this.$i18n.locale) {
				case 'zh':
					this.list = this.$config.hireListZH
					this.inf_text =
						'<p>厦门沐游网络科技有限公司，是一家具有自主研发和运营能力的游戏公司，以打造最受玩家喜爱和世界级影响力的游戏精品为公司愿景诚聘各类英才；</p> <p>HR 邮箱： seja@muugame.com</p> <p>公司地址：厦门市集美区软件园三期B区09栋901</p>'
					break
				case 'en':
					this.list = this.$config.hireListEN
					this.inf_text =
						'<p>Xiamen Muyou Network Technology Co., Ltd. (referred to as “Muyou Network”) was established in December 2018 in Software part III Jimei District, Xiamen City, Fujian Province. Muyou Network has gathered a group of experienced and excellent teams, focusing on mobile internet products development and promotion operations.</p> <p>E-Mail： hr@muugame.com</p> <p>Address：1901-6, Building B4, Chengyi North Street, Jimei District, Xiamen</p>'
					break
			}
			this.activeNames = this.$route.query.id
		}
	}
}
</script>
<style scoped>
.inf {
	display: flex;
	margin-bottom: 10px;
	padding: 30px 20px;
}
.inf_icon {
	padding-top: 10px;
	margin-right: 40px;
	width: 80px;
}
.inf_text {
	flex: 1;
}
.inf_text >>> p {
	margin-top: 0;
	margin-bottom: 15px;
	line-height: 30px;
	font-size: 16px;
}
.detail {
	padding: 0 20px;
	line-height: 40px;
	font-size: 16px;
	background-color: #f3f3f3;
}
</style>
<style>
.el-collapse-item {
	margin-bottom: 10px;
}
.el-collapse-item__header {
	background-color: #14a6a3 !important;
	height: 50px !important;
	font-size: 1.5rem !important;
	padding-left: 20px;
	color: #fff !important;
}
.el-collapse-item__wrap {
	border: none !important;
}
</style>
